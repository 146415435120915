<template>
  <div class="app-container">
    <el-form
      :inline="true"
      :model="queryForm"
      class="demo-form-inline account-top"
    >
      <el-form-item label="发贴帐号">
        <el-input
          v-model="queryForm.account"
          placeholder="发帖帐号id"
        ></el-input>
      </el-form-item>
      <el-form-item label="任务状态">
        <el-select v-model="queryForm.status" placeholder="任务状态" clearable>
          <el-option label="完成" value="1"></el-option>
          <el-option label="待执行" value="0"></el-option>
          <el-option label="执行失败" value="-1"></el-option>
          <el-option label="帐号失效" value="-11"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="发帖用户" v-if="userInfo.is_staff">
        <el-input v-model="queryForm.user" placeholder="发帖用户id"></el-input>
      </el-form-item>
      <el-form-item label="搜索">
        <el-input
          v-model="queryForm.search"
          placeholder="标题、正文等..."
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleSearch">查询</el-button>
      </el-form-item>
      <el-button type="primary" @click="fetchData">刷新</el-button>
      <el-button type="danger" @click="batchDel">批量删除</el-button>
    </el-form>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
      @selection-change="handleSelectionChange"
      class="tableList2"
    >
      <el-table-column type="selection" width="30"></el-table-column>
      <el-table-column prop="id" label="ID" width="100"></el-table-column>
      <el-table-column
        prop="content"
        label="帖子内容"
        show-overflow-tooltip
      ></el-table-column>
      <!-- <el-table-column prop="accountId" label="发帖帐号ID"></el-table-column> -->
      <el-table-column prop="account_entity" label="发帖帐号">
        <template slot-scope="scope">
          {{ scope.row.account_entity ? scope.row.account_entity.name : null }}
        </template>
      </el-table-column>
      <el-table-column
        prop="publish_time"
        label="设定发帖时间"
      ></el-table-column>
      <!-- <el-table-column
        prop="media"
        label="多媒体链接"
        show-overflow-tooltip
      ></el-table-column> -->
      <el-table-column prop="media_entity" label="发帖素材">
        <template slot-scope="scope">
          {{ scope.row.media_entity ? scope.row.media_entity.name : null }}
        </template>
      </el-table-column>
      <el-table-column prop="post_url" label="帖子链接">
        <!-- 点击复制 -->
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            content="点击复制"
            placement="top"
          >
            <div
              class="tag-read"
              style="cursor:pointer;"
              @click="copy(scope.row.post_url)"
              :data-clipboard-text="copyValue"
            >
              {{ scope.row.post_url }}
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <el-tag
            :type="getStatusType(scope.row.status, statusMap)"
            disable-transitions
          >
            {{ getStatusLabel(scope.row.status, statusMap) }}
          </el-tag>
          <el-button
            v-if="scope.row.status === 1 && scope.row.screenshot_entity"
            type="primary"
            size="small"
            style="margin-left: 10px"
            @click="downScreenshot(scope.row.screenshot_entity.id)"
          >
            下载截图
          </el-button>
        </template>
      </el-table-column>

      <!-- <el-table-column label="创建人" v-if="isManager">
        <template slot-scope="scope">
          {{ scope.row.user.oauthAccount }}
        </template>
      </el-table-column>
      <el-table-column label="创建人备注" v-if="isManager">
        <template slot-scope="scope">{{ scope.row.user.note }}</template>
      </el-table-column> -->
      <el-table-column label="创建人" v-if="isManager">
        <template slot-scope="scope">
          {{
            scope.row.user_entity ? scope.row.user_entity.oauth_account : null
          }}
        </template>
      </el-table-column>
      <el-table-column label="创建人备注" v-if="isManager">
        <template slot-scope="scope">
          {{ scope.row.user_entity ? scope.row.user_entity.note : null }}
        </template>
      </el-table-column>
      <el-table-column
        prop="create_time"
        label="任务创建时间"
      ></el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="queryForm.pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="queryForm.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="count"
    ></el-pagination>
  </div>
</template>

<script>
import { delPostTaskByIds, getPostTaskList } from '@/api/facebook'
import { getMaterial } from '@/api/material'
import { getStatusType, getStatusLabel, getStatusTip } from '@/utils/status'
import Clipboard from 'clipboard'
import { getManagerStatus } from '@/utils/manager'

export default {
  data() {
    return {
      userInfo: this.$store.state.user.userInfo,
      list: null,
      listLoading: true,
      count: 0,
      isManager: getManagerStatus(),
      multipleSelection: [],
      queryForm: {
        pageIndex: 1,
        pageSize: 10,
        account: '',
        status: '',
        user: '',
        search: ''
      },

      statusMap: {
        1: {
          type: 'success',
          label: '执行成功'
        },
        0: {
          type: 'primary',
          label: '待执行'
        },
        '-1': {
          type: 'danger',
          label: '执行失败',
          tip: '任务执行失败，该任务将由人工介入处理'
        },
        '-2': {
          type: 'danger',
          label: '多媒体链接错误',
          tip: '多媒体链接无法打开或者无法下载，请尝试使用本地视频上传后发表'
        },
        '-3': {
          type: 'danger',
          label: '多媒体链接失效',
          tip: '多媒体链接无法打开或者无法下载，请尝试使用本地视频上传后发表'
        },
        '-4': {
          type: 'danger',
          label: '重复的推文',
          tip:
            '该twitter帐号近期已发表过该推文，请修改发表的推文内容后重新提交任务'
        },
        '-5': {
          type: 'danger',
          label: '视频不符合要求'
        },
        '-6': {
          type: 'danger',
          label: '推文超长'
        },
        '-10': {
          type: 'danger',
          label: '已删除'
        },
        '-11': {
          type: 'danger',
          label: '帐号失效',
          tip: '该Facebook帐号已失效，请更换Facebook帐号重试'
        }
      },
      copyValue: 'value'
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    getStatusType,
    getStatusLabel,
    getStatusTip,
    async fetchData() {
      this.listLoading = true
      const { all_count: count, items } = await getPostTaskList(this.queryForm)
      this.count = count
      this.list = items
      this.listLoading = false
    },
    async handleSearch() {
      this.queryForm.pageIndex = 1
      await this.fetchData()
    },
    handleSizeChange(pageSize) {
      this.queryForm.pageSize = pageSize
      // 分页变更时处理
      this.fetchData()
    },
    handleCurrentChange(pageIndex) {
      this.queryForm.pageIndex = pageIndex
      // 分页变更时处理
      this.fetchData()
    },
    handleSelectionChange(items) {
      this.multipleSelection = []
      items.forEach(item => {
        if (this.multipleSelection.indexOf(item.id) === -1) {
          this.multipleSelection.push(item.id)
        }
      })
    },
    async downScreenshot(id) {
      // const url = await getPostTaskScreenshot(id)
      const { signature_url: signature_url } = await getMaterial(id)
      window.location.href = signature_url
    },
    async batchDel() {
      if (this.multipleSelection.length < 1) {
        this.$message.error('请至少选择一项需要删除的数据')
      } else {
        this.$confirm('此操作将删除该任务, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(async () => {
            await delPostTaskByIds(this.multipleSelection).then(resp => {
              this.fetchData()
              // this.$message({
              //   type: 'success',
              //   message: '删除成功!'
              // })
            })
          })
          .catch(() => {})
      }
    },
    // 点击复制
    copy(value) {
      const that = this
      that.copyValue = value
      var clipboard = new Clipboard('.tag-read')
      clipboard.on('success', e => {
        this.$message.success('复制成功！')
        //  释放内存
        clipboard.destroy()
      })
      clipboard.on('error', e => {
        // 不支持复制
        this.$message.error('该浏览器不支持复制!')
        // 释放内存
        clipboard.destroy()
      })
    }
  }
}
</script>
